<template>
  <div class="about">
    <Header />
    <div class="banner">
      <div class="container">
        <div class="title">About Us</div>
      </div>
    </div>
    <div class="main">
      <div class="container">
        <div class="warpper">
          <div class="title" style="text-align: center">
            Welcome to Selling Your Car.
          </div>
          <p>
            We understand the burden of selling your car. The amount of
            paperwork, negotiating the right price – even finding a buyer! Those
            are only some factors that make the vehicle trade a hassle for many
            owners wishing to get rid of their motors. That’s why we set out to
            create a car buying service that removes the fuss and gives vehicle
            owners the cash they deserve.
          </p>
          <p>
            Selling Your Car began to help our community enjoy a more trusted,
            efficient, and rewarding car buying service. Our philosophy is that
            selling vehicles should be a simple as possible for the owner. We
            strive to achieve those ambitions through our dedicated mission,
            profound vision, company values, and community promise.
          </p>
          <div class="line"></div>
          <div class="title" style="text-align: center">Our Mission</div>
          <div class="title">
            Our mission is to provide Australians with the easiest way to sell
            their cars.
          </div>
          <p>
            We use our expert insight, state-of-the-art technology, and timely
            logistics to ensure that owners can trade their vehicles fast and
            with the price they deserve. Selling Your Car is quickly becoming
            the preferred car resource because of our dedication to clients.
          </p>
          <div class="line"></div>
          <div class="title" style="text-align: center">Our Vision</div>
          <div class="title">
            The vision that drives our business is a dealership that makes
            buying
            <span style="color: #0c61b9">your car a seamless process.</span>
          </div>
          <p>
            We want our clients thinking that all that’s needed is to contact us
            about selling their vehicle – and we’ll take care of the rest. Let
            our team do the work for you and deliver the cash for your car right
            into your pocket.
          </p>
          <div class="line"></div>
          <div class="title" style="text-align: center">Our Values</div>
          <p>
            Selling Your Car incorporates three values into every quote and
            query – efficiency, commitment, and sincerity. Discover the meaning
            behind each value below:
          </p>
          <div class="title">
            <span style="color: #0c61b9">Efficiency</span>
          </div>
          <p>
            We have spent time working out the most efficient process for
            selling vehicles. Our team ensures no hassle or fuss behind any
            quote or query as soon as you contact us.
          </p>
          <div class="title">
            <span style="color: #0c61b9">Commitment</span>
          </div>
          <p>
            We commit to making your life easier when selling your vehicle.
            There’s no other dealer that will provide clients with such
            dedicated customer service. We build a comfortable and secure
            environment to sell your car.
          </p>
          <div class="title">
            <span style="color: #0c61b9">Sincerity</span>
          </div>
          <p>
            There’s nothing worse than when owners think they aren’t receiving
            the correct value of their vehicle. We build profound trust around
            our services to ensure sellers receive the maximum amount of the
            car’s value.
          </p>
          <div class="line"></div>
          <div class="title" style="text-align: center">Our Promise</div>
          <p>
            We put our money where our mouth is when it comes to our service.
            Selling Your Car offers a promise of one of the most seamless car
            sales experiences Australia-wide. We guarantee client satisfaction
            with a quick quote, excellent logistics, and most importantly – cash
            in hand in no time.
          </p>
        </div>
      </div>
    </div>
    <Fast />
    <div class="row">
      <div class="container">
        <div class="warpper">
          <div class="left">
            <div class="title">Did you know?</div>
            <div class="desc">
              By simplifying the process, we fast track your car sale using the
              convenience of a full concierge service while giving you the
              safety of a trusted source.
            </div>
            <div class="icons">
              <div class="item">
                <div class="icon">
                  <img src="~@/assets/images/icon_p.png" alt="" />
                </div>
                <div class="info">
                  <div class="top">SAME DAY</div>
                  <div class="bottom">Call Back</div>
                </div>
              </div>
              <div class="item">
                <div class="icon">
                  <img src="~@/assets/images/icon_c.png" alt="" />
                </div>
                <div class="info">
                  <div class="top">SAME DAY</div>
                  <div class="bottom">Inspection</div>
                </div>
              </div>
              <div class="item">
                <div class="icon">
                  <img src="~@/assets/images/icon_m.png" alt="" />
                </div>
                <div class="info">
                  <div class="top">SAME DAY</div>
                  <div class="bottom">Payment</div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <img src="~@/assets/images/right_icon.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
import Fast from "@/components/fast/Fast";
export default {
  components: {
    Header,
    Footer,
    Fast,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="less">
.about {
  width: 100%;
  position: relative;
  .banner {
    width: 100%;
    height: 286px;
    background: url("~@/assets/images/banner_3.png") no-repeat;
    background-size: cover;
    background-position: center;
    padding: 160px 0 80px 0;
    box-sizing: border-box;
    .title {
      font-size: 40px;
      font-family: Ubuntu-Bold, Ubuntu;
      font-weight: bold;
      color: #ffffff;
      line-height: 47px;
      text-align: center;
    }
  }
  .main {
    width: 100%;
    .warpper {
      padding: 80px 0;
      box-sizing: border-box;
      p {
        font-family: Ubuntu-Regular, Ubuntu;
        font-weight: 400;
        margin-bottom: 32px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.6);
        line-height: 19px;
        span {
          font-weight: bold;
        }
      }
      .title {
        font-size: 24px;
        font-family: Ubuntu-Bold, Ubuntu;
        font-weight: 800;
        color: rgba(0, 0, 0, 0.9);
        margin-bottom: 20px;
      }
      .line {
        width: 100%;
        height: 1px;
        background: #eaeaea;
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }
  }
  .row {
    width: 100%;
    background: #0f2f4e;
    .warpper {
      padding: 100px 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        width: 936px;
        .title {
          font-size: 40px;
          font-family: Ubuntu-Bold, Ubuntu;
          font-weight: bold;
          color: #ffffff;
          line-height: 47px;
        }
        .desc {
          font-size: 24px;
          font-family: Ubuntu-Bold, Ubuntu;
          font-weight: bold;
          color: #ffffff;
          line-height: 28px;
          margin-top: 40px;
        }
        .icons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 40px;
          .item {
            display: flex;
            align-items: center;
            .icon {
              width: 87px;
              height: 87px;
              margin-right: 16px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .info {
              font-size: 16px;
              font-family: Ubuntu-Bold, Ubuntu;
              font-weight: bold;
              color: #ffffff;
              line-height: 24px;
            }
          }
        }
      }
      .right {
        width: 160px;
        height: 160px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
@media only screen and (max-width: 690px) {
  .about {
    .banner {
      height: 132px;
      padding: 84px 0 20px 0;
      .title {
        font-size: 24px;
        line-height: 28px;
      }
    }
    .main {
      .warpper {
        padding: 20px 0;
        p {
          font-size: 10px;
          line-height: 15px;
          margin-bottom: 20px;
        }
        .title {
          font-size: 12px;
          line-height: 14px;
          margin-bottom: 10px;
        }
        .line {
          margin: 0 0 20px 0;
        }
      }
    }
    .row {
      display: none;
    }
  }
}
@media only screen and (max-width: 1050px) and (min-width: 690px) {
  .about {
    .banner {
      height: 132px;
      padding: 84px 0 20px 0;
      .title {
        font-size: 24px;
        line-height: 28px;
      }
    }
    .main {
      .warpper {
        padding: 20px 0;
        p {
          font-size: 10px;
          line-height: 15px;
          margin-bottom: 20px;
        }
        .title {
          font-size: 12px;
          line-height: 14px;
          margin-bottom: 10px;
        }
        .line {
          margin: 0 0 20px 0;
        }
      }
    }
    .row {
      display: none;
    }
  }
}
</style>
